/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import ModelCard from "../components/ModelCard";
import config from "../../../core/env.core";
import { t } from "i18next";

const HorizontalTemplate = ({
  models = [],
  currentModel,
  onSelected,
  noteDetail,
  currency,
  onSelectOption
}) => {
  const getModelsList = (_currency, _models) => {
    if (!_currency || !_models || _models.length < 1) return [];
    const arr = [..._models]?.sort((a, b) => {
      const priceA = a.price[_currency.dbKey];
      const priceB = b.price[_currency.dbKey];
      return priceA - priceB;
    });
    return arr;
  };

  return (
    <div className="h-full flex flex-col space-y-8 px-5">
      <ul role="button" className="flex-1 flex items-stretch space-x-3">
        {getModelsList(currency, models).map((model, index) => (
          <li onClick={() => onSelected(model)} key={index.toString()} className="flex-1">
            <ModelCard
              model={model}
              isSelected={currentModel?.modelId === model?.modelId}
              currency={currency}
              onSelectOption={onSelectOption}
            />
          </li>
        ))}
      </ul>
      {/* <div className="flex flex-col items-center space-y-5">
        <p>{noteDetail}</p>
        <a href={config.zapp_main_page_url} className=" text-white cursor-pointer">
          <span className="underline">{t("Back to homepage")}</span>
        </a>
      </div> */}
    </div>
  );
};

export default HorizontalTemplate;
