/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ActionType, DisplayLayout } from "../../../../models/property";
import GridSelectorLayout from "../../components/GridSelectorLayout";
import SelectorLayout from "../../components/SelectorLayout";
import { Virtuoso } from "react-virtuoso";
import { FormType } from "../../../../models/formType";
import { NumericFormat } from "react-number-format";
import i18next from "i18next";
import { Breakpoint } from "../../../../theme/config.them";
import TotalPriceHorizontalSection from "../../components/TotalPriceHorizaontalSection";
const CustomizationTemplate = ({
  model,
  customizations = [],
  properties = [],
  initialSelectedProperties = [],
  onUpdateProperty,
  onRemoveProperty,
  onChangeProperty,
  onConfirm,
  isOrderMode,
  onChangeRang,
  selectedGroupCustomizationPart,
  onSaveOrderConfiguration
  // onSelectProperty
}) => {
  const { t } = useTranslation();
  const [selectedProperties, setSelectedProperties] = useState([]);

  const currency = useSelector((state) => state.currency);
  const virtuoso = useRef(null);
  const behaviorScroll = "smooth";

  const [height, setHeight] = useState(0);

  const scrollPanel = useRef(null);

  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0
  });

  const [width, setWidth] = useState(window.innerWidth);

  const screenResponsiveEvent = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };

  useEffect(() => {
    setSelectedProperties(initialSelectedProperties);
  }, [initialSelectedProperties]);

  useEffect(() => {
    screenResponsiveEvent();
    setHeight(scrollPanel.current.clientHeight / (width >= Breakpoint.md ? 2 : 1));
  }, []);

  useEffect(() => {
    const index = customizations?.findIndex(
      (custom) => custom.categoryId === selectedGroupCustomizationPart.groupId
    );
    virtuoso.current.scrollToIndex({
      index: index,
      align: "start",
      behavior: behaviorScroll
    });
  }, [customizations, selectedGroupCustomizationPart]);

  const getPropertiesByCustomizationPart = (partId) => {
    return properties.filter((property) => property.customizationPartId === partId);
  };

  const getSelectedPropertyByCustomization = (partId) => {
    return selectedProperties?.filter((property) => property.customizationPartId === partId) || [];
  };

  const totalAdditionalOptional = useMemo(() => {
    return (
      selectedProperties?.reduce((acc, cur) => acc + (cur?.price[currency.dbKey] || 0), 0) || 0
    );
  }, [selectedProperties, currency]);

  const totalPrice = useMemo(() => {
    return totalAdditionalOptional + model.price[currency.dbKey];
  }, [totalAdditionalOptional, model.price, currency.dbKey]);

  /**
   *
   * @param {Property} value
   * @param {'add' | 'remove'} state
   */
  const handleOnSelectProperty = (value, state, actionType, custom) => {
    if (state === "add" && [ActionType.selector, ActionType.toggle].includes(actionType)) {
      let index;
      if ([FormType.MultipleRelation, FormType.Single].includes(custom.formType)) {
        index = selectedProperties.findIndex(
          (ele) => ele.customizationPartId === value.customizationPartId
        );
      } else {
        const form = custom.formGroups.map((form) => form.formId === value.formId);
        const action = form?.actionType ?? custom.actionType;

        index =
          action === ActionType.multipleSelector
            ? -1
            : selectedProperties.findIndex(
                (ele) =>
                  ele.customizationPartId === value.customizationPartId &&
                  ele.formId === value.formId
              );
      }

      if (index >= 0) {
        const clone = [...selectedProperties];
        const old = clone[index];
        clone[index] = value;
        setSelectedProperties([...clone]);
        onUpdateProperty(value, old);
      } else {
        setSelectedProperties([...selectedProperties, value]);
        onUpdateProperty(value, null);
      }
    } else if (state === "add" && ActionType.multipleSelector === custom.actionType) {
      setSelectedProperties([...selectedProperties, value]);
    } else {
      /// remove
      let index;
      if (
        [FormType.Single, FormType.MultipleRelation].includes(custom.formType) &&
        ActionType.multipleSelector !== custom.actionType
      ) {
        index = selectedProperties.findIndex(
          (ele) => ele.customizationPartId === value.customizationPartId
        );
      } else if (ActionType.multipleSelector === custom.actionType) {
        index = selectedProperties.findIndex((ele) => ele.propertyId === value.propertyId);
      } else {
        index = selectedProperties.findIndex(
          (ele) =>
            ele.customizationPartId === value.customizationPartId && ele.formId === value.formId
        );
      }

      if (!index) return;
      selectedProperties.splice(index, 1);
      setSelectedProperties([...selectedProperties]);
      onRemoveProperty(value);
    }
  };

  useEffect(() => {
    onChangeProperty(selectedProperties);
  }, [onChangeProperty, selectedProperties]);

  const handleOnUpdateProperty = (value) => {
    const index = selectedProperties.findIndex(
      (ele) => ele.customizationPartId === value.customizationPartId
    );
    if (index >= 0) {
      const clone = selectedProperties;
      clone[index] = value;
      setSelectedProperties([...clone]);
    } else {
      setSelectedProperties([...selectedProperties, value]);
    }
  };

  return (
    <div className="h-full flex flex-col items-stretch relative ">
      <div className="flex-1 lg:mx-auto lg:container lg:max-w-3xl xl:max-w-none ">
        <div ref={scrollPanel} className="h-full overflow-y-hidden ">
          <Virtuoso
            data={customizations || []}
            ref={virtuoso}
            components={{
              Footer: () => {
                return (
                  <div style={{ height: height }}>
                    <div className="flex items-stretch lg:hidden border-b lg:container lg:mx-auto">
                      <div className="flex-1 flex flex-col justify-center px-3 space-y-3 font-semibold">
                        <div className="flex items-center justify-between">
                          <p className=" text-xs">{t("Additional Options")}</p>
                          <p className="inline-flex space-x-1 text-xs">
                            <span>{currency.prefix}</span>
                            <NumericFormat
                              value={totalAdditionalOptional || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-xs">{t("Total Price")}</p>
                          <p className="text-xs inline-flex space-x-1">
                            <span>{currency.prefix}</span>
                            <NumericFormat
                              value={totalPrice || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => onConfirm(selectedProperties)}
                        className=" bg-white bg-opacity-40 h-[62px] px-5">
                        {isOrderMode ? <p>{t("Save Configuration")}</p> : <p>{t("Check out")}</p>}
                      </button>
                    </div>
                  </div>
                );
              }
            }}
            itemContent={(index, custom) => {
              if (custom?.displayLayout === DisplayLayout.RowSelectorLayout) {
                return (
                  <SelectorLayout
                    title={custom.language?.translation[i18next.language]}
                    currency={currency}
                    actionType={custom.actionType}
                    options={getPropertiesByCustomizationPart(custom.partId)}
                    values={getSelectedPropertyByCustomization(custom.partId)}
                    onSelectProperty={(v, s, a) => handleOnSelectProperty(v, s, a, custom)}
                  />
                );
              } else {
                return (
                  <GridSelectorLayout
                    title={custom.language?.translation[i18next.language]}
                    currency={currency}
                    layout={custom.displayLayout}
                    formType={custom.formType}
                    formGroups={custom.formGroups}
                    actionType={custom.actionType}
                    languageForms={custom.language_form}
                    options={getPropertiesByCustomizationPart(custom.partId)}
                    values={getSelectedPropertyByCustomization(custom.partId)}
                    onSelectProperty={(v, s, a) => handleOnSelectProperty(v, s, a, custom)}
                  />
                );
              }
            }}
          />
        </div>
      </div>

      <div className=" hidden xl:flex flex-col px-5 py-5 justify-center space-y-3">
        <div className="flex items-center justify-between text-xl">
          <p>{t("Additional Options")}</p>
          <p className="  inline-flex space-x-1">
            <span>{currency.prefix}</span>
            <NumericFormat
              value={totalAdditionalOptional || 0}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </div>
        <div className="flex items-center justify-between text-xl pb-5">
          <p>{t("Total Price")}</p>
          <p className="  inline-flex space-x-1">
            <span>{currency.prefix}</span>
            {/* <span>{totalPrice}</span> */}
            <NumericFormat value={totalPrice || 0} displayType={"text"} thousandSeparator={true} />
          </p>
        </div>
        <button
          onClick={() => onConfirm(selectedProperties)}
          className="py-3 self-center font-semibold w-2/3 bg-button-color bg-opacity-40 text-xl h-[67px] rounded mt-5 text-black">
          {isOrderMode ? <p>{t("Save Configuration")}</p> : <p>{t("Check out")}</p>}
        </button>
      </div>
      <div className=" hidden lg:block xl:hidden py-1">
        <TotalPriceHorizontalSection
          currency={currency}
          totalPrice={totalPrice}
          isOrderMode={isOrderMode}
          onConfirm={() => onConfirm(selectedProperties)}
        />
      </div>
    </div>
  );
};

export default memo(CustomizationTemplate);
